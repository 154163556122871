
import React, { Component } from "react";




export default class Blog extends Component {
  render() {
    return (

      <div className="container blog-container mt-4">
        <div className="row main-row ">
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="blog-img text-center">
              <img className="img-fluid" src={this.props.img} />
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="blog-title mt-lg-5 mt-md-2 mt-sm-1">
              <h5 className="text-center">
                {this.props.title1}
              </h5>
              <h2 className="text-center p-2">
                {this.props.title2}
              </h2>
           {/**  <h4 className="text-center pb-4 ">
                {this.props.message}
              </h4>*/}  
              <h4 className="text-center">
                Cuvântul <b>„DA”</b> rămâne sfânt, <br/>
                E de granit, e jurământ <br/>
                Prin el vom fi nedespărțiți, <br/>
                Încrezători și fericiți. <br/>
                În deplină credință, <br/> dragoste și înțelegere, <br/>
                NOI, <br/>
                <b>Leonid și Galina</b> <br/>
                Am hotărât să ne unim destinele, <br/>
                de aceea vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. <br/>
                În acest context avem plăcerea de a vă invita să vă bucurați alături de noi participând
la celebrarea căsătoriei noastre. <br/>
<b>Vă așteptăm cu drag!</b>
              </h4>
              <p className="text-center pb-5">
              {this.props.ps}
              </p>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

