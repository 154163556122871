import restaurant from "./images/gi.jpeg";
import img_card from "./images/2w.jpeg";
import imgOmSuflet from "./images/headerms.png";
import imgheader from "./images/header.webp";
import img2 from "./images/1w.jpeg";
import imgheadermiini from "./images/wedding.jpg";
import imgheadermiinimb from "./images/wedding.jpg";

{/*andreeasolcoci@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatii Online",
        mire: "Leonid",
        mireasa: "Galina",
        data: "11 August 2022",
        data_confirmare: "15 iulie 2022",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheader,
        email: "60226017l@gmail.com", 
       tel: "+373 604 64 029",
       phone: "tel:+37360464029",
       viber: "viber://chat?number=%2B37360464029",
       whatsapp: "https://wa.me/+37360464029",
       messenger: "https://www.messenger.com/t/leonea.fdef",
       tel1: "+373 xxx",
       phone1: "tel:+3737xxx",
       viber1: "viber://chat?number=%2B373xx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/xxx",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "GIOWine Hotel & Restaurant",
            data: "11 August 2022, joi, ora 16:00",
            adress: "Strada Crinilor 7, Cricova",
            harta: "https://goo.gl/maps/pXcnAKqFF6j3A8hXA",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10856.649726086272!2d28.8571441!3d47.1350837!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x50615887ea3c8fbf!2sGIOWine%20Hotel%20%26%20Restaurant!5e0!3m2!1sro!2s!4v1656837824932!5m2!1sro!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "",
            ps: "",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {
            img: imgOmSuflet,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "CU BINECUVÂNTAREA PĂRINȚILOR",
            parintii_nume1: "Leonid & Aliona",
            parintii_nume2: "Iurie & Natalia",
            nasii: "ȘI SUSȚINEREA NAȘILOR",
            nasii_nume: "Rodion & Roxana",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta.\" –Emanuel Swdenborg",
        }
    ],

}

export default data;